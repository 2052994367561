import { ApolloLink } from "@apollo/client";

import { getUTMs } from "@shared/hooks/useUnauthUrlFields/useUnauthUrlFields";
export const getAddUTMLink = () => {
  return new ApolloLink((operation, forward) => {
    operation.extensions.sessionUTMs = getUTMs();

    return forward(operation);
  });
};
