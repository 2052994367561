import { isDevelopment } from "./fullscriptEnv/fullscriptEnv";

/* eslint-disable no-console */
const hello = () => {
  if (isDevelopment()) return;

  const logo = `
   +++++++++             ++++++++++
  ++++++++++++++      ++++++++++++++
 +++++   +++++++++  +++++++++   +++++
+++++        ++++++++++++        +++++
++++           ++++++++          +++++
++++     ++++   +++++    ++++     ++++
++++     +++++   ++++  ++++++     ++++
++++     +++++++ ++++ ++++++     +++++
+++++      ++++++++++++++++      ++++
 +++++       ++++++++++++       +++++
  +++++       +++++++++        +++++
   +++++        +++++        ++++++
    +++++++      ++++      +++++++
     ++++++++    ++++   ++++++++
       +++++++++++++++++++++++
          +++++++++++++++++
             ++++++++++
`;

  console.log(`%c ${logo}`, "color: #88B04B");
};

export { hello };
