import { lazy, Suspense } from "react";

import { isSSR, isDevelopment } from "@shared/utils/fullscriptEnv/fullscriptEnv";

const ReactAxe = lazy(() => import("./ReactAxe/ReactAxe"));

const LazyReactAxe = () => {
  // We don't care about running this on the server side or in production/staging
  if (!isDevelopment() || isSSR()) return null;

  if (
    (typeof process !== "undefined" && process.env.REACT_AXE_ENABLED) ||
    window?.ENV?.REACT_AXE_ENABLED
  ) {
    return (
      <Suspense fallback={null}>
        <ReactAxe />
      </Suspense>
    );
  }

  return null;
};

export { LazyReactAxe };
