import { ApolloLink } from "@apollo/client";

/**
 * An apollo link that adds metadata from the frontend to all GQL requests as an extension
 * Ex: version and buildTimestamp which are used by backend to determine which version of the frontend is used at the time of an error
 */
export const getFrontendMetaDataLink = () => {
  return new ApolloLink((operation, forward) => {
    operation.extensions.frontendMetaData = {
      version: process.env.CI_COMMIT_SHA,
      buildTimestamp: window.ENV.BUILD_TIMESTAMP,
    };

    return forward(operation);
  });
};
